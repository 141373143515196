import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import { ButtonLink } from "components/buttons"

// Icons
import Check from "assets/icons/checkmark.svg"

const StyledContainer = styled(Container)`
  padding: 0;
  margin: 0 auto;
`

const StyledUnderHero = styled.section`
  padding: 20px;
  text-align: center;
  max-width: 1150px;
  margin: 0 auto;

  ${breakpoint.medium`
    padding: 60px 30px 90px;
  `}

	h1 {
		font-size: 32px;
		padding: 10px 0;
		margin-bottom: 20px;
		text-align: center;

      ${breakpoint.medium`
		text-align: left;
		width: 100%;
		font-size: 42px;
		padding: 0;
      `}
	 }


	.point {
		color: ${colors.purpleDark};
	 	text-align: center;
		display: inline-grid;
		font-weight: 700;
		line-height: 1.2em;
		margin-bottom: 4.8px!important;
		padding: 0!important;
		font-size: 20px;
	
		${breakpoint.medium`
			display: block;
			text-align: left;
		`}
		
		&::before {
            content: url(${Check});
            
			${breakpoint.small`
				margin-right: 6px;
			`}
		}
	}

	.detail {
		text-align: center;
		line-height: 1.4em;
		padding: 0;
		margin-bottom: 0.5rem;
		width: 100%;

		${breakpoint.medium`
			width: 80%;
			padding: 0 0.4rem 0 2rem;
			text-align: left;
		`}
	}

	.row {
		display: flex;
		margin-top: 60px;
		flex-direction: column;

		${breakpoint.medium`
		flex-direction: row;
		`}
		
	}
	
	.column {
		flex: 0;
		align-self: center;
		min-width: 26%;

		&.first {
			min-width: 27%;
		}

		&.middle {
			flex: 1;
			min-width: 45%;
			align-self: flex-start;
			margin-top: 50px;
			align-self: center;

			${breakpoint.medium`
				align-self: left;
			`}
	
		}
				
		p {
			padding: 0 1rem;
			margin: 0 auto 1rem !important;
		}

	}
		
	.centerImage {
		width: 100%;
		padding: 15px;
		max-width: 450px;
		display: block;
	  
		${breakpoint.small`
			margin: 0 auto;
			padding: 25px;
			max-width: 520px;
		`}
	}

	.columnBullets {
		display: grid;
		grid-auto-flow: row;
		width: 100%;
		max-width: 1100px;
		margin: 0 auto;

		${breakpoint.medium`
			grid-auto-flow: row;
		`}

	}

	.columnBullets > div {
		margin-top: 10px;
	}

      a {
   		color: ${colors.orange};   
      }
      
      a:hover {
   		color: ${colors.purpleDark};   
      }
	}

	${ButtonLink} {
        width: auto;
		margin-block: 20px;
		color: ${colors.charcoal}; 

		${breakpoint.medium`
			margin-block: 40px;
		`}

		&:hover {
			color: ${colors.white}; 
		}
    }

  
`

const UnderHero = props => {
	return (
		<StyledUnderHero>
			<StyledContainer>
				<div className="row">
					<div className="columnBullets">
					<h1 data-aos="fade-up">Instrument-free&nbsp;PCR<br />for respiratory health</h1>
						<div data-aos="fade-up">
							<p className="point">Easy to use</p>
							<p className="detail">One swab, three targets: COVID-19, influenza A and influenza B</p>
						</div>
						<div data-aos="fade-up" data-aos-delay="200">
							<p className="point">Affordable</p>
							<p className="detail">No maintenance or service contracts</p>
						</div>
						<div data-aos="fade-up" data-aos-delay="300">
							<p className="point">Scalable</p>
							<p className="detail">Easy to run multiple test devices at the same time</p>
						</div>
					</div>
					<div className="column middle"  data-aos="fade-up" data-aos-delay="400">
						<StaticImage
							src="../../../assets/images/respiratory-health-test/device-angle.png"
							width={800}
							alt="Visby Medical Respiratory Health Test"
							className="centerImage"
							formats={["webp"]}
						/>
					<ButtonLink backgroundcolor="primary" to="/how-to-buy/" data-aos="fade-up" data-aos-delay="100">Order Now</ButtonLink>
					</div>
				</div>
			</StyledContainer>
		</StyledUnderHero>
	)
}

export default UnderHero
